<template>
    <layout-login>
        <template v-slot:title>
            {{ __('interface.register-customer') }}
        </template>
        <ion-item>
            <ion-label position="floating">{{ __('interface.email') }}</ion-label>
            <ion-input type="email" v-model="loginForm.email" required autocomplete="email"/>
        </ion-item>
        <ion-item>
            <ion-label position="floating">{{ __('interface.password') }}</ion-label>
            <ion-input :type="showPassword.password ? 'text' : 'password'" v-model="loginForm.password" required autocomplete="new-password"/>
            <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow('password')">
              <ion-icon :icon="showPassword.password ? eyeOff : eye"></ion-icon>
            </ion-button>
        </ion-item>
        <requirement-list v-if="loginForm.password.length > 0" :requirements="passwordRequirements(loginForm.password)" />
        <ion-item>
            <ion-label position="floating">{{ __('interface.confirm-new-password') }}</ion-label>
            <ion-input :type="showPassword.confirm ? 'text' : 'password'" v-model="loginForm.confirmPassword" required autocomplete="new-password"/>
            <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow('confirm')">
              <ion-icon :icon="showPassword.confirm ? eyeOff : eye"></ion-icon>
            </ion-button>
        </ion-item>
        <requirement-list v-if="loginForm.confirmPassword.length > 0 || loginForm.password.length > 0" :requirements="repeatPasswordRequirements(loginForm.password, loginForm.confirmPassword)" />
        
        <ion-list>
          <ion-list-header>
            {{ __('interface.personal-information') }}
          </ion-list-header>
          <ion-item>
              <ion-label position="floating">{{ __('interface.first-name') }}</ion-label>
              <ion-input v-model="form.first_name" required autocomplete="given-name" autocapitalize="words"/>
          </ion-item>
          <ion-item>
              <ion-label position="floating">{{ __('interface.last-name') }}</ion-label>
              <ion-input v-model="form.last_name" required autocomplete="family-name" autocapitalize="words"/>
          </ion-item>
          <ion-item>
            <ion-label position="floating">{{ __('interface.date-of-birth') }}</ion-label>
            <ion-datetime display-format="DD/MM/YYYY" picker-format="DD MMMM YYYY" v-model="form.date_of_birth"
                          @ionBlur="form.date_of_birth = $event.target.value"/>
          </ion-item>
          <ion-item>
            <ion-label position="floating">{{ __('interface.gender') }}</ion-label>
            <ion-select @ionBlur="form.sex = $event.target.value" v-model="form.sex" :ok-text="__('interface.ok')"
                        :cancel-text="__('interface.cancel')">
              <ion-select-option v-for="option in genderOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-row>
            <ion-col size="8">
              <ion-item v-if="form.height_unit === 'cm'">
                <ion-label position="floating">{{ __('interface.height') }}</ion-label>
                <ion-input v-model="heightAux.cm" @ionInput="cmChanged" autocomplete="off"/>
              </ion-item>
              <ion-item v-if="form.height_unit === 'inch'">
                <ion-label position="floating">{{ __('interface.height') }}</ion-label>
                <ion-select @ionBlur="feetChanged" v-model="heightAux.ft"
                            :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
                  <ion-select-option v-for="(option, key) in feetOptions" :key="key" :value="option">
                    {{ option }}
                  </ion-select-option>
                </ion-select>          
                </ion-item>
              <ion-item v-if="form.height_unit === 'inch'">
                <ion-label position="floating">&nbsp;</ion-label>
                <ion-select @ionBlur="inchChanged" v-model="heightAux.inch"
                            :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
                  <ion-select-option v-for="(option, key) in inchOptions" :key="key" :value="option">
                    {{ option }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col size="4">
              <ion-item>
                <ion-label position="floating">{{ __('interface.unit') }}</ion-label>
                <ion-select @ionBlur="form.height_unit = $event.target.value" v-model="form.height_unit"
                            :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
                  <ion-select-option v-for="(option, key) in heightOptions" :key="key" :value="option.value">
                    {{ option.label }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item v-if="form.height_unit === 'inch'">
                <ion-label position="floating">&nbsp;</ion-label>
                <ion-input disabled :value="__('interface.inches')" autocomplete="off"/>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="8">
              <ion-item>
                <ion-label position="floating">{{ __('interface.weight') }}</ion-label>
                <ion-input v-model="form.weight" v-on:ionInput="form.weight = $event.target.value"/>
              </ion-item>
            </ion-col>
            <ion-col size="4">
              <ion-item>
                <ion-label position="floating">{{ __('interface.unit') }}</ion-label>
                <ion-select @ionBlur="form.weight_unit = $event.target.value" v-model="form.weight_unit"
                            :ok-text="__('interface.ok')" :cancel-text="__('interface.cancel')">
                  <ion-select-option v-for="(option, key) in weightOptions" :key="key" :value="option.value">
                    {{ option.label }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-list>
        <ion-item>
          <ion-checkbox color="primary" v-model="form.terms_accepted" slot="start"></ion-checkbox>
          <ion-label text-wrap> {{ __('interface.agree-statement') }} <a href="https://www.the-spott.com/terms-of-service-client">{{ __('interface.agree-licence-customer') }}</a>&nbsp; {{ __('interface.and') }}&nbsp; <a href="https://www.the-spott.com/privacy">{{ __('interface.agree-privacy-customer') }}</a> {{ __('interface.agree-privacy-customer-processing') }}</ion-label>
        </ion-item>
        <ion-button expand="full" class="ion-margin-vertical" @click="actionRegister" :disabled="!arePasswordsRequirementsMet(loginForm.password, loginForm.confirmPassword) || !form.terms_accepted">
            {{ __('interface.register') }}
        </ion-button>
        <div class="ion-text-center ion-padding">
            <router-link to="/login">{{ __('interface.go-login') }}</router-link>
        </div>
        <div class="ion-text-center ion-padding">
            <router-link to="/register-confirm-code">{{ __('interface.forgot-password-go-code') }}</router-link>
        </div>
    </layout-login>    
</template>

<script>
import {Auth} from '@aws-amplify/auth'
import RequirementList from '@/components/RequirementList.vue';
import {passwordRequirements} from '@/utils/passwordRequirements'
import {profileHelper} from '@/utils/profileHelper'
import {API} from "@/utils/apiWrapper"
import LayoutLogin from '@/layouts/LayoutLogin.vue';
import {alertController} from "@ionic/vue";
import {IonCol, IonRow, IonIcon, IonListHeader, IonCheckbox} from "@ionic/vue";
import {showMessages} from '@/utils/showMessages'
import {showValidationMessages} from '@/utils/showValidationMessages'
import {eye, eyeOff} from 'ionicons/icons';

export default {
  mixins: [passwordRequirements, profileHelper],
  name: 'Register',
  components: {
    RequirementList,
    LayoutLogin,
    IonCol,
    IonRow,
    IonIcon,
    IonListHeader,
    IonCheckbox
  },
  data () {
    return {
      loginForm: {
        email: '',
        password: '',
        confirmPassword: ''
      },
      form: {
        first_name: '',
        last_name: '',
        date_of_birth: '1996-01-01',
        sex: 'female',
        height: 0,
        height_unit: 'cm',
        weight: 0,
        weight_unit: 'kg',
        terms_accepted: false
      },
      loadingState: false,
      showPassword: {
        password: false,
        confirm: false
      }
    }
  },        
  setup() {
    return {
      eye,
      eyeOff
    }
  },
  methods: {
    async showAlert(error) {
      const alert = await alertController.create({
        header: 'Error',
        message: error,
        buttons: ['OK'],
      });
      return alert.present();
    },
    async actionRegister () {
      this.loadingState = true

      const signupResponse = await Auth.signUp({
        username: this.loginForm.email,
        password: this.loginForm.password
      }).catch(error => {
        this.showAlert(error.message)
      })

      const body = { ...this.form }
      body.height = this.calcHeightForSave(this.form.height_unit)
      body.cognito_id = signupResponse.userSub

      API.post('NoAuth', '/register-customer', {
        body: body
      }).then(() => {
        this.$router.push({ name: 'RegisterConfirmCode', query: { email: this.loginForm.email } })
        this.$tracking.event({
          eventName: 'register',
          category: 'registration',
          event: {
            username: this.loginForm.email
          }
        });
      }).catch(async (e) => {
        await showMessages(e.response.data.messages);
        await showValidationMessages(e.response.data.error);
      }).finally(() => {
        this.loadingState = false
      })
    },
    toggleShow (input) {
      this.showPassword[input] = !this.showPassword[input]
    }
  }
}
</script>

<style lang="scss" scoped>
  ion-col {
    padding: 0;
  }
</style>
